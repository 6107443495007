* {
    margin: 0;
    padding: 0;
}
html,
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-position: left;
}